@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url(../../../node_modules/swiper/css/swiper.min.css);
@import url(../../../node_modules/normalize.css/normalize.css);
@import url(../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
@import url(../../../node_modules/aos/dist/aos.css);
* {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a, button, span {
  display: inline-block; }

input, textarea {
  display: block;
  width: 100%; }

table {
  width: 100%;
  border-collapse: collapse; }

a {
  background-color: transparent;
  text-decoration: none; }

img {
  border: none;
  max-width: 100%;
  height: auto; }

a, button {
  outline: 0;
  cursor: pointer; }

li {
  list-style: none; }

button {
  background: 0 0;
  border: none; }

a, button, input, textarea {
  color: #000;
  font-weight: inherit;
  -webkit-appearance: none;
  transition: all .5s ease; }

:after, :before {
  box-sizing: border-box; }

textarea {
  resize: none;
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input {
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input[type=number] {
  -moz-appearance: textfield; }

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=submit] {
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block; }

input[type=search]::-webkit-search-cancel-button {
  background: 0 0;
  display: none; }

.text-align-left {
  text-align: left; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.full-img img {
  width: 100%; }

figure {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 5px;
  font-size: .75em;
  opacity: .8; }

[hidden] {
  display: none !important; }

.img-full {
  width: 100%; }

.clearfix:after {
  content: '';
  clear: both;
  display: block; }

* div, a, span, input, textarea, :after, :before {
  transition: .5s; }

h1, .h1 {
  margin: 0;
  font-weight: 900;
  font-size: 50px;
  line-height: 61px;
  text-transform: uppercase;
  color: #FFFFFF; }
  @media (max-width: 1140px) {
    h1, .h1 {
      font-size: 40px;
      line-height: 49px; } }
  @media (max-width: 566px) {
    h1, .h1 {
      font-size: 30px;
      line-height: 39px; } }
  @media (max-width: 424px) {
    h1, .h1 {
      font-size: 22px;
      line-height: 28px; } }

h2, .h2 {
  font-weight: 900;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  max-width: 680px;
  margin: 0 auto;
  color: #37394F; }
  h2:after, h2:before, .h2:after, .h2:before {
    content: '';
    display: block;
    position: absolute;
    top: 21px;
    width: 215px;
    height: 5px;
    background: #FF8919; }
  h2:after, .h2:after {
    right: -250px; }
  h2:before, .h2:before {
    left: -250px; }
  h2 span, .h2 span {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px; }
  @media (max-width: 1023px) {
    h2:before, h2:after, .h2:before, .h2:after {
      display: none; } }
  @media (max-width: 566px) {
    h2, .h2 {
      font-size: 30px;
      line-height: 39px; } }
  @media (max-width: 424px) {
    h2, .h2 {
      font-size: 20px;
      line-height: 25px; }
      h2 span, .h2 span {
        font-size: 18px; } }

h3, .h3 {
  margin: 0; }

h4, .h4 {
  margin: 0; }

h5, .h5 {
  margin: 0; }

h6, .h6 {
  margin: 0; }

body {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-style: normal;
  font-weight: normal;
  background: #fff;
  font-size: 16px; }
  @media (max-width: 735px) {
    body body {
      font-size: 14px; } }

.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

header {
  width: 100%; }

main {
  flex: auto;
  display: block; }

footer {
  margin: auto auto 0 auto;
  width: 100%; }

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2; }

.container {
  max-width: 1480px; }

.container-fluid {
  max-width: 1920px; }

.btn-row {
  text-align: center; }
  .btn-row .btn {
    margin: 10px; }

.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -20px; }
  .column .column-2 {
    width: 50%;
    padding: 10px 20px; }
  .column .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px; }

.fancybox-navigation {
  position: static; }

.swiper-button {
  outline: none; }
  .swiper-button.swiper-button-prev {
    transform: scaleX(-1); }
  .swiper-button:before, .swiper-button:after {
    display: none; }

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px; }
  .swiper-pagination .swiper-pagination-bullet {
    opacity: 1;
    outline: none; }

input::-webkit-input-placeholder {
  color: #000; }

input::-moz-placeholder {
  color: #000; }

input:-moz-placeholder {
  color: #000; }

input:-ms-input-placeholder {
  color: #000; }

textarea::-webkit-input-placeholder {
  color: #000; }

textarea::-moz-placeholder {
  color: #000; }

textarea:-moz-placeholder {
  color: #000; }

textarea:-ms-input-placeholder {
  color: #000; }

input:focus::-webkit-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus::-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-ms-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

textarea:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus::-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

/*modal*/
.modal {
  display: none;
  max-width: 100%;
  overflow: visible;
  padding: 87px 33px 39px 34px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: 510px; }
  @media (max-width: 424px) {
    .modal {
      padding: 67px 13px 29px 13px; } }
  .modal .main-link {
    width: 240px;
    margin: 0 auto;
    display: block; }
  .modal h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #37394F;
    margin-bottom: 30px; }
  .modal .fancybox-close-small {
    width: 52px;
    height: 52px; }
    .modal .fancybox-close-small svg {
      color: #C4C4C4; }

.form-item {
  width: 100%;
  max-width: 390px;
  margin: 0 auto 45px auto; }
  .form-item:last-child {
    margin: 0 auto 33px auto; }
  .form-item input {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #37394F;
    padding: 0 12px 12px 20px;
    border-bottom: 1px solid #686868; }
    .form-item input::placeholder {
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #37394F; }

.form-checkbox {
  font-size: 12px;
  line-height: 15px;
  color: #37394F;
  margin-bottom: 50px;
  padding-left: 38px;
  display: block;
  max-width: 175px; }
  .form-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    width: 21px;
    height: 21px;
    background: #37394F;
    border-radius: 3px;
    z-index: -1; }

.form-check {
  position: absolute;
  left: 0;
  top: 0;
  width: 21px;
  height: 21px;
  background: #37394F;
  border-radius: 3px; }
  .form-check:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 11px;
    height: 11px;
    transform: translate(-50%, -50%);
    background: url("../img/icons/ok.svg") no-repeat center;
    background-size: contain;
    opacity: 0; }

.form-checkbox input:checked ~ .form-check:before {
  opacity: 1; }

.modal, .modal > * {
  -webkit-transform: translateY(150px);
  -moz-transform: translateY(150px);
  -ms-transform: translateY(150px);
  -o-transform: translateY(150px);
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s; }

.fancybox-slide--current .modal, .fancybox-slide--current .modal > * {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  padding: 37px 0 0 0;
  transition: .5s; }
  .header.out {
    transform: translateY(-100%);
    transition: .5s; }
  .header.sticky {
    padding: 0; }
    .header.sticky .header__info {
      display: none; }
    .header.sticky .header__menu:after {
      opacity: 1; }
    .header.sticky .menu-item a {
      padding: 30px 0 18px 0; }
    .header.sticky .header__logo {
      margin-right: 55px;
      margin-left: 0;
      height: 42px; }
      .header.sticky .header__logo span {
        display: none; }
      .header.sticky .header__logo img {
        display: none; }
        .header.sticky .header__logo img.fixed-logo {
          display: block;
          width: 100%;
          height: 100%; }
  .header__info {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .header__item {
    margin-right: 30px;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF; }
    .header__item span {
      width: 25px;
      height: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 30px; }
      .header__item span img {
        max-width: 100%;
        max-height: 100%; }
    .header__item:first-child {
      pointer-events: none; }
    .header__item:hover {
      color: #FFA71E; }
    .header__item:first-child {
      max-width: 270px; }
    .header__item:nth-child(2) {
      margin-right: 74px;
      font-size: 16px; }
    .header__item:last-child {
      margin-right: 0;
      font-weight: 500;
      font-size: 30px;
      line-height: 37px; }
  .header__menu {
    display: flex;
    background: #FFFFFF;
    border-radius: 3px;
    align-items: center; }
    .header__menu:after {
      content: '';
      display: block;
      position: absolute;
      left: -2000px;
      right: -2000px;
      top: 0;
      bottom: 0;
      background: #FFFFFF;
      z-index: -1;
      opacity: 0;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); }
  .header__logo {
    width: 245px;
    height: 19px;
    margin-left: 30px;
    margin-right: 65px; }
    .header__logo--icon {
      position: absolute;
      width: 110px;
      height: 81px;
      bottom: 170%;
      left: 65px; }
    .header__logo img {
      max-width: 100%;
      max-height: 100%; }
      .header__logo img.fixed-logo {
        display: none; }
  .header .wrap-nav {
    flex: 1; }
  .header .menu {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header .menu-item {
      margin-right: 10px; }
      .header .menu-item a {
        padding: 15px 0 15px 0;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #686868; }
        .header .menu-item a:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          background: #73C167;
          width: 0;
          opacity: 0; }
        .header .menu-item a:hover {
          color: #000000; }
          .header .menu-item a:hover:after {
            width: 100%;
            opacity: 1; }
  .header.header-page {
    background: #00274C; }
  .header .header__menu {
    border-radius: 3px 3px 0 0; }

.open-nav {
  display: none;
  width: 16px;
  height: 18px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto; }
  .open-nav .line {
    height: 4px;
    background: #C4C4C4;
    width: 100%; }

.close-nav {
  display: none;
  width: 16px;
  height: 18px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto; }
  .close-nav .line {
    height: 4px;
    background: #C4C4C4;
    width: 100%; }

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: none; }
  .overlay.open {
    display: block; }

@media (max-width: 1340px) {
  .header {
    padding: 15px 0 0 0; }
  .header__item:last-child {
    font-size: 25px; }
  .header__item:nth-child(2) {
    margin-right: 30px; }
  .header__item span {
    margin-right: 15px; }
  .header__logo {
    width: 200px;
    margin-left: 10px;
    margin-right: 25px; }
  .header .menu-item a {
    font-size: 17px;
    padding: 10px 0 10px 0; }
  .header__logo--icon {
    position: absolute;
    width: 95px;
    height: 65px;
    bottom: 150%;
    left: 50px; } }

@media (max-width: 1023px) {
  .header.sticky .header__menu:after {
    display: none; }
  .header.sticky .header__menu {
    padding: 6px 19px 6px 29px;
    margin: 0 -8px; }
  .header.sticky {
    padding-top: 7px; }
  .header__item:first-child {
    display: none; }
  .header__item:nth-child(2) {
    display: none; }
  .header__item:last-child {
    font-size: 30px;
    font-weight: normal; }
  .header .wrap-nav {
    transform: translateX(1000%);
    z-index: 99999;
    flex: auto;
    position: fixed;
    right: 5px;
    top: 7px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    transition: .5s;
    padding: 66px 13px 30px 30px; }
    .header .wrap-nav.open {
      transform: translateX(0);
      transition: .5s; }
  .header {
    padding: 36px 0 0 0; }
  .header__logo--icon {
    bottom: 188%; }
  .header__logo {
    width: 245px; }
  .header__logo {
    margin-left: 0; }
  .header__menu {
    padding: 20px 19px 15px 29px; }
  .close-nav {
    display: flex;
    position: absolute;
    right: 19px;
    top: 18px; }
  .header .menu-item a {
    padding: 0 0 6px 0; }
  .header.sticky .menu-item a {
    padding: 0 0 6px 0; }
  .header .menu-item {
    margin-right: 0;
    margin-bottom: 16px; }
    .header .menu-item:last-child {
      margin-bottom: 0; }
  .header .menu {
    display: block; }
  .open-nav {
    display: flex; } }

@media (max-width: 474px) {
  .header__logo--icon {
    left: -20px; }
  .header__item:last-child {
    font-size: 20px; } }

@media (max-width: 424px) {
  .header__logo {
    width: 180px; } }

.footer {
  padding: 30px 0 43px 0;
  background: #00274C; }
  .footer .container {
    display: flex; }
  .footer__info {
    width: 215px;
    margin-right: 153px; }
  .footer__logo {
    width: 100%;
    height: 67px;
    margin-bottom: 32px; }
    .footer__logo img {
      max-width: 100%;
      max-height: 100%; }
  .footer__note {
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF; }
  .footer__wp {
    width: calc(100% - 368px);
    display: flex;
    flex-wrap: wrap; }
  .footer .wrap-nav {
    margin-bottom: 63px;
    width: 100%; }
  .footer .menu {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .footer .menu-item {
      margin-right: 7px; }
      .footer .menu-item:last-child {
        margin-right: 0; }
      .footer .menu-item a {
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF; }
        .footer .menu-item a:hover {
          color: #FFA800; }
  .footer__tel {
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    text-align: right;
    color: #FFFFFF; }
    .footer__tel.hidden-tel {
      display: none; }
    .footer__tel:hover {
      color: #FFA800; }
    .footer__tel span {
      width: 19px;
      height: 19px;
      margin-right: 12px; }
      .footer__tel span img {
        width: 100%;
        height: 100%; }
  .footer__develop {
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
    margin-left: auto;
    display: flex;
    align-items: center; }
    .footer__develop span {
      width: 25px;
      height: 25px;
      margin-right: 11px; }
      .footer__develop span img {
        max-width: 100%;
        max-height: 100%; }

@media (max-width: 1340px) {
  .footer__info {
    margin-right: 30px; }
  .footer__wp {
    width: calc(100% - 245px); }
  .footer .menu-item a {
    font-size: 18px; } }

@media (max-width: 1023px) {
  .footer__info {
    width: 215px;
    margin-right: 30px;
    margin-bottom: 100px; }
  .footer .container {
    flex-wrap: wrap; }
  .footer__note {
    display: block; }
  .footer__tel {
    display: none; }
  .footer__wp {
    width: 100%; }
  .footer__tel.hidden-tel {
    display: block;
    width: calc(100% - 245px); } }

@media (max-width: 767px) {
  .footer .menu {
    display: block; }
  .footer .wrap-nav {
    margin-bottom: 0; }
  .footer .menu-item {
    margin-right: 0;
    margin-bottom: 45px; }
    .footer .menu-item:last-child {
      margin-bottom: 0; }
    .footer .menu-item a {
      font-size: 20px; }
  .footer__develop {
    position: absolute;
    right: 0;
    bottom: 0; } }

@media (max-width: 566px) {
  .footer__tel.hidden-tel {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
    text-align: left; }
  .footer__info {
    margin: 0 auto 30px auto;
    text-align: center; }
  .footer__develop {
    position: relative;
    right: auto;
    top: 0;
    margin: 0 auto; }
  .footer .wrap-nav {
    margin-bottom: 30px; }
  .footer .menu-item {
    text-align: center; }
  .footer__tel.hidden-tel {
    text-align: center; } }

.homescreen__wrapper {
  padding: 302px 0 147px 0; }

.homescreen__wp {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 123px; }

.homescreen__text {
  width: calc(100% - 560px); }
  .homescreen__text h1 {
    max-width: 580px; }

.homescreen__links {
  width: 540px;
  z-index: 5;
  margin-left: 20px;
  margin-top: 10px; }
  .homescreen__links a {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 100%;
    margin-bottom: 30px;
    padding: 28px 10px 33px 35px; }
    .homescreen__links a:hover:after {
      transform: skewX(10deg); }
    .homescreen__links a:after {
      z-index: -1;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #73C167;
      transform: skewX(-10deg); }
    .homescreen__links a:last-child {
      margin-bottom: 0; }
      .homescreen__links a:last-child:after {
        background: #EE3524; }
    .homescreen__links a:nth-child(2):after {
      background: #FF8919; }

.homescreen__bg {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0; }
  .homescreen__bg img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .homescreen__bg:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(17, 88, 156, 0.8) 0%, rgba(46, 141, 231, 0.2) 100%); }

.homescreen .main-link {
  width: 460px;
  padding: 36px;
  font-size: 24px;
  line-height: 29px;
  padding: 31.5px; }

.directions {
  background: #EEEEEE; }
  .directions__text {
    width: 50%;
    padding: 50px 40px 60px 0; }
    .directions__text h3 {
      font-weight: 900;
      font-size: 25px;
      line-height: 30px;
      text-transform: uppercase;
      color: #37394F;
      margin-bottom: 44px; }
    .directions__text p {
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
      margin-bottom: 18px; }
    .directions__text .main-link {
      margin-top: 22px;
      width: 245px; }
  .directions__block {
    display: flex;
    align-items: stretch; }
    .directions__block:nth-child(2n) .directions__text {
      padding-left: 75px;
      margin-left: auto;
      padding-right: 0; }
    .directions__block:nth-child(2n) .directions__img {
      right: auto;
      left: 0; }
  .directions__img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%; }
    .directions__img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.achievements {
  margin-bottom: 90px;
  padding: 55px 0 90px 0; }
  .achievements__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    .achievements__bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .achievements__bg:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(17, 88, 156, 0.5), rgba(17, 88, 156, 0.5)), linear-gradient(180deg, rgba(17, 88, 156, 0.8) 0%, rgba(46, 141, 231, 0.2) 100%); }
  .achievements__heading {
    max-width: 705px;
    margin: 0 auto 64px auto; }
    .achievements__heading h2 {
      max-width: 480px;
      color: #FFFFFF;
      margin-bottom: 40px; }
      .achievements__heading h2 span {
        margin-top: -15px; }
    .achievements__heading p {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF; }
  .achievements__wp {
    display: flex;
    max-width: 1330px;
    justify-content: space-between; }
  .achievements__item {
    width: 30%; }
  .achievements__text span {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 100%; }
  .achievements__text h3 {
    font-weight: 900;
    font-size: 100px;
    line-height: 122px;
    text-align: right;
    text-transform: uppercase;
    color: #FFFFFF; }
  .achievements__text p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    text-transform: uppercase;
    color: #FFFFFF;
    max-width: 290px;
    margin-left: auto; }
  .achievements__img {
    z-index: -1;
    position: absolute;
    top: -20px;
    right: -120px;
    width: 200px;
    height: 200px;
    opacity: .5; }
    .achievements__img img {
      max-width: 100%;
      max-height: 100%; }

.complete {
  margin-bottom: 115px; }
  .complete__slider {
    padding-bottom: 60px; }
  .complete .swiper-scrollbar.complete__scrollbar {
    width: 100%;
    height: 1px;
    background: #686868;
    border-radius: 0; }
    .complete .swiper-scrollbar.complete__scrollbar .swiper-scrollbar-drag {
      background: #73C167;
      height: 5px;
      top: -2px; }
  .complete__heading {
    margin-bottom: 54px; }
    .complete__heading h2 {
      margin-bottom: 17px; }
      .complete__heading h2:after, .complete__heading h2:before {
        width: 93px; }
      .complete__heading h2:after {
        right: -150px; }
      .complete__heading h2:before {
        left: -150px; }
    .complete__heading p {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      color: #37394F; }
  .complete__bg {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .complete__bg img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .5s; }
  .complete__item {
    margin-bottom: 30px;
    min-height: 253px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .complete__item:last-child {
      margin-bottom: 0; }
    .complete__item:hover .complete__bg img {
      transition: .5s;
      transform: scale(1.1); }
  .complete__text {
    background: rgba(255, 255, 255, 0.8);
    padding: 12px 10px 12px 14px; }
    .complete__text p {
      font-size: 18px;
      line-height: 21px;
      color: #37394F; }
      @media (max-width: 900px) {
        .complete__text p {
          font-size: 15px;
          line-height: 18px; } }

.documents {
  padding: 73px 90px 83px 90px;
  margin-bottom: 90px; }
  .documents h2 {
    color: #FFFFFF;
    max-width: 710px;
    margin-bottom: 60px; }
    .documents h2:after, .documents h2:before {
      width: 93px; }
    .documents h2:after {
      right: -150px; }
    .documents h2:before {
      left: -150px; }
  .documents__slider {
    padding: 0 0 60px 0;
    max-width: 1255px;
    margin: 0 auto; }
  .documents__item {
    overflow: hidden; }
    .documents__item img {
      transition: .5s; }
    .documents__item:hover {
      opacity: .7; }
      .documents__item:hover img {
        transition: .5s; }
  .documents__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    .documents__bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .documents__bg:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%); }
  .documents .swiper-scrollbar.documents__scrollbar {
    background: #FFFFFF;
    height: 1px; }
    .documents .swiper-scrollbar.documents__scrollbar .swiper-scrollbar-drag {
      background: #73C167;
      height: 5px; }

.way {
  margin-bottom: 60px; }
  .way__bg {
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    opacity: 0.6;
    z-index: -1;
    bottom: 0; }
    .way__bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .way__bg:after {
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .way__slider {
    margin-bottom: 90px;
    padding-bottom: 80px;
    margin-top: -60px;
    padding-top: 60px; }
    .way__slider .swiper-scrollbar.way__scrollbar {
      background: #686868;
      height: 1px; }
      .way__slider .swiper-scrollbar.way__scrollbar .swiper-scrollbar-drag {
        background: #73C167;
        height: 5px;
        top: -2.5px; }
  .way__heading {
    margin-bottom: 60px; }
    .way__heading h2 {
      margin-bottom: 12px; }
      .way__heading h2:after {
        right: -130px; }
      .way__heading h2:before {
        left: -130px; }
    .way__heading p {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      color: #37394F; }
  .way__wp {
    display: flex;
    align-items: flex-end; }
  .way__point {
    transition: .5s;
    background: #EE3524;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    margin-bottom: 52px;
    background: url("../img/icons/circle.svg") no-repeat center;
    background-size: contain; }
    .way__point:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 99%;
      height: 114px;
      width: 1px;
      background: #C4C4C4; }
    .way__point:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 99%;
      height: 70px;
      width: 1px;
      background: #C4C4C4; }
  .way__line {
    width: 100%;
    height: 9px;
    left: 0;
    top: 10px;
    position: absolute;
    background: #FFA71E;
    height: 1px; }
    .way__line:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -5px;
      right: 0;
      bottom: -5px;
      background: rgba(255, 167, 30, 0.2); }
  .way__item {
    width: 20%; }
    .way__item:hover .way__point {
      transform: scale(1.1);
      transition: .5s; }
    .way__item:first-child .way__line {
      transform: rotate(-21.24deg);
      left: 32px;
      top: 138px; }
    .way__item:nth-child(2) .way__line {
      transform: rotate(5.34deg);
      left: 32px;
      top: 205px; }
    .way__item:nth-child(3) .way__line {
      transform: rotate(-13.58deg);
      left: 32px;
      top: 155px; }
    .way__item:nth-child(4) .way__line {
      transform: rotate(-31.04deg);
      left: 0px;
      top: 116px;
      width: 330px; }
    .way__item:last-child .way__line {
      display: none; }
    .way__item h4 {
      font-weight: 900;
      font-size: 50px;
      line-height: 61px;
      color: #73C167;
      margin-bottom: 94px;
      padding-left: 52px; }
    .way__item p {
      padding-left: 60px;
      font-size: 18px;
      line-height: 21px;
      color: #37394F;
      max-width: 280px; }
      @media (max-width: 900px) {
        .way__item p {
          font-size: 15px;
          line-height: 18px; } }
    .way__item:nth-child(2) {
      margin-bottom: 110px; }
    .way__item:nth-child(3) {
      margin-bottom: 87px; }
    .way__item:nth-child(4) {
      margin-bottom: 160px; }
    .way__item:last-child {
      margin-bottom: 330px;
      margin-top: -60px; }

.clients__slider {
  max-width: 1255px;
  margin: 0 auto;
  padding: 29px 70px;
  background: #FFFFFF;
  border-radius: 20px 20px 0px 0px; }

.clients__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; }
  .clients__item img {
    max-width: 100%;
    max-height: 100%; }

.clients__next, .clients__prev {
  border: 4px solid #C4C4C4;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px; }
  .clients__next:after, .clients__prev:after {
    color: #C4C4C4;
    font-size: 25px; }

.partners__heading {
  margin-bottom: 60px; }
  .partners__heading h2 {
    margin-bottom: 12px; }
    .partners__heading h2:after {
      right: -150px; }
    .partners__heading h2:before {
      left: -150px; }
  .partners__heading p {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #37394F; }

.partners .clients__slider {
  border-radius: 0 0 20px 20px; }

.vacancy {
  padding: 243px 0 0 0;
  margin-bottom: 100px;
  margin-top: -158px; }
  .vacancy__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 100px; }
    .vacancy__bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .vacancy__heading {
    margin-bottom: 63px; }
    .vacancy__heading h2 {
      margin-bottom: 12px; }
      .vacancy__heading h2:after {
        right: -150px; }
      .vacancy__heading h2:before {
        left: -150px; }
    .vacancy__heading p {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #37394F; }
  .vacancy__slider {
    padding-bottom: 73px; }
    .vacancy__slider .swiper-scrollbar.vacancy__scrollbar {
      background: #686868;
      height: 1px; }
      .vacancy__slider .swiper-scrollbar.vacancy__scrollbar .swiper-scrollbar-drag {
        background: #73C167;
        height: 5px;
        top: -2.5px; }
  .vacancy__item {
    padding: 31px 30px 0 30px;
    background: #FFFFFF;
    border: 1px solid transparent;
    transition: .5s; }
    .vacancy__item:hover {
      border: 1px solid #B8B8B8;
      transition: .5s; }
    .vacancy__item h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 100%;
      color: #37394F;
      padding-bottom: 23px;
      margin-bottom: 23px; }
      .vacancy__item h3:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 33px;
        height: 2px;
        background: #FFA800; }
    .vacancy__item p {
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      color: #686868;
      margin-bottom: 23px; }
    .vacancy__item ul {
      margin-bottom: 32px; }
      .vacancy__item ul li {
        font-size: 16px;
        line-height: 100%;
        color: #686868;
        margin-bottom: 15px; }
        .vacancy__item ul li:last-child {
          margin-bottom: 0; }
    .vacancy__item .main-link {
      max-width: 245px;
      width: 100%;
      margin: 0 auto -24px auto;
      top: 7px; }
  .vacancy__salary {
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    color: #00274C;
    margin-bottom: 47px; }

#map {
  height: 472px;
  width: 100%;
  margin-bottom: 65px; }

.contacts {
  margin-bottom: 85px; }
  .contacts__wp {
    display: flex;
    justify-content: space-between; }
  .contacts__info {
    max-width: 520px;
    width: 100%;
    padding-left: 120px;
    margin-right: 20px; }
    .contacts__info h2 {
      margin: 0 0 30px 0;
      text-align: left; }
      .contacts__info h2:after, .contacts__info h2:before {
        display: none; }
    .contacts__info--item {
      display: flex;
      display: flex;
      align-items: flex-start;
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
      margin-bottom: 20px; }
      .contacts__info--item:first-child {
        pointer-events: none; }
      .contacts__info--item:last-child {
        margin-bottom: 0; }
      .contacts__info--item span {
        width: 20px;
        height: 20px;
        margin-right: 19px; }
        .contacts__info--item span img {
          width: 100%;
          height: 100%; }
      .contacts__info--item:hover {
        color: #FFA71E; }
  .contacts__form {
    max-width: 540px;
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .contacts__form h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
      color: #37394F; }
    .contacts__form .main-link {
      width: 245px;
      margin-left: auto;
      padding: 0;
      height: 49px; }
  .contacts__item {
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 35px; }
    .contacts__item input {
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
      padding: 0 10px 12px 14px;
      border-bottom: 1px solid #686868; }
      .contacts__item input::placeholder {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        color: #37394F; }
  .contacts__checkbox {
    cursor: pointer;
    display: block;
    width: 220px;
    padding-left: 38px;
    font-size: 17px;
    line-height: 20px;
    color: #37394F; }
    .contacts__checkbox input {
      background: #37394F;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      width: 21px;
      height: 21px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
  .contacts__check {
    background: #37394F;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 21px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 0; }
    .contacts__check:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background: url("../img/icons/ok.svg") no-repeat center;
      opacity: 0; }

.contacts__checkbox input:checked ~ .contacts__check:before {
  opacity: 1; }

@media (max-width: 1240px) {
  .way__item:nth-child(4) .way__line {
    transform: rotate(-35.04deg);
    left: 21px;
    top: 114px;
    width: 268px; }
  .way__item:nth-child(3) .way__line {
    transform: rotate(-16.58deg);
    left: 32px;
    top: 163px; }
  .way__item:first-child .way__line {
    transform: rotate(-23.24deg);
    left: 32px;
    top: 140px; }
  .homescreen__wp {
    margin-bottom: 80px; } }

@media (max-width: 1160px) {
  .way__item:nth-child(4) .way__line {
    transform: rotate(-38.04deg); }
  .way__item:first-child .way__line {
    transform: rotate(-26.24deg);
    top: 145px; }
  .homescreen__text {
    width: calc(100% - 360px); }
  .homescreen__wrapper {
    padding: 302px 40px 147px 40px; }
  .homescreen__links {
    width: 340px; }
    .homescreen__links a {
      font-size: 16px;
      padding: 18px 5px 23px 15px; }
  .directions__text {
    padding: 30px 20px 30px 0; }
  .directions__block:nth-child(2n) .directions__text {
    padding-left: 35px; }
  .achievements__img {
    right: 0; }
  .achievements__item {
    margin-right: 10px; }
  .achievements__text h3 {
    font-size: 80px; }
  .achievements__text p {
    font-size: 18px; }
  .achievements__wp {
    flex-wrap: wrap;
    justify-content: center; }
  .contacts__info {
    padding-left: 0; } }

@media (max-width: 1040px) {
  .way__line {
    display: none; } }

@media (max-width: 900px) {
  .way__line {
    display: block;
    transform: none !important;
    top: 155px !important; }
  .way__item:nth-child(4) .way__line {
    width: 105%; }
  .contacts__form .main-link {
    width: 173px; }
  .homescreen__links {
    width: 440px; }
  .homescreen__text {
    width: calc(100% - 460px); }
  .homescreen__block .container {
    display: block; }
  .homescreen__links a {
    font-size: 20px; }
  .homescreen__wrapper {
    padding: 193px 0 78px 0; }
  .achievements__heading h2 {
    margin-bottom: 36px; }
  .way__item h4 {
    font-size: 36px;
    line-height: 55px; }
  .way__point {
    margin-bottom: 21px; }
  .way__item {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    min-width: 290px; }
  .achievements {
    margin-bottom: 40px; }
  .way__item h4 {
    margin-bottom: 63px; }
  .way__slider {
    margin-top: 0;
    padding-top: 52px;
    padding-bottom: 0;
    margin-bottom: 80px;
    overflow-x: scroll; }
    .way__slider .swiper-slide {
      display: none;
      width: 1223px;
      overflow: scroll; }
      .way__slider .swiper-slide::-webkit-scrollbar {
        display: none; }
      .way__slider .swiper-slide:first-child {
        display: flex; }
    .way__slider::-webkit-scrollbar {
      display: none; }
  .swiper-wrapper {
    width: 100%; }
  .way__slider {
    padding-left: 60px; }
  .way__slider .swiper-scrollbar.way__scrollbar {
    display: none; }
  .way__bg:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }
  .way__item {
    width: 290px; }
  .way__heading {
    margin-bottom: 50px; }
  .way__bg {
    top: 0; }
  .complete {
    margin-bottom: 58px; }
  .documents {
    padding: 47px 0 85px 0;
    margin-bottom: 0; } }

@media (max-width: 767px) {
  .homescreen__text {
    width: 100%;
    margin-bottom: 37px; }
  .homescreen__links {
    width: 100%;
    margin-top: 0;
    margin-left: 0; }
  .homescreen__text h1 {
    max-width: 100%; }
  .contacts__form {
    max-width: 100%; }
  .homescreen__links a {
    padding: 31px 5px 30px 15px; }
  .contacts__wp {
    display: block; }
  .contacts__info {
    max-width: 100%;
    margin-bottom: 75px; }
    .contacts__info--item {
      max-width: 350px; }
  .contacts__form .main-link {
    width: 240px; }
  .contacts {
    margin-bottom: 55px; }
  .partners .clients__slider {
    border-radius: 0; }
  .vacancy__heading {
    margin-bottom: 59px; }
  .vacancy__bg {
    top: 25px; }
    .vacancy__bg:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0, #FFFFFF 30.36%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.9; }
  .complete__slider {
    margin: 0 -20px; }
  .vacancy__slider {
    margin: 0 -20px; }
  .documents__slider {
    margin: 0 -20px;
    padding-bottom: 57px; }
  .clients__slider {
    border-radius: 0;
    padding: 41px 30px; }
  .clients {
    margin: 0 -20px; }
  .achievements {
    margin-bottom: 0; }
  .achievements__heading h2 span {
    margin-top: 0; }
  .achievements__wp {
    display: block; }
  .achievements__item {
    max-width: 100%;
    width: 450px;
    margin: 0 auto 93px auto; }
    .achievements__item:last-child {
      margin: 0 auto 0 auto; }
  .achievements__text h3 {
    font-size: 100px; }
  .achievements__text p {
    font-size: 20px; }
  .achievements__text {
    max-width: 255px;
    margin-right: auto; }
  .achievements__img {
    opacity: 1;
    right: 30px; }
  .homescreen .main-link {
    width: 460px;
    margin: 0 auto;
    display: block;
    max-width: 100%; }
  .homescreen__wp {
    margin-bottom: 64px; }
  .homescreen__wrapper {
    padding: 193px 13px 78px 13px; }
  .directions__img {
    position: relative;
    left: auto;
    top: auto;
    width: 100%; }
  .directions__block:nth-child(2n) .directions__text {
    padding-left: 13px; }
  .directions__text {
    width: 100%; }
  .directions__block {
    display: block; }
  .directions__text {
    padding: 26px 13px 44px 13px; }
  #map {
    margin-bottom: 78px; } }

@media (max-width: 600px) {
  .clients__slider {
    padding: 41px 20px; } }

@media (max-width: 566px) {
  .homescreen__links a {
    padding: 20px 5px 20px 15px; }
  .homescreen .main-link {
    padding: 17.5px; }
  .contacts__checkbox {
    margin-bottom: 30px; }
  .homescreen__links {
    max-width: 460px;
    margin: 0 auto; }
  .homescreen .main-link {
    font-size: 20px; }
  .contacts__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px; }
  .contacts__form .main-link {
    width: 100%; }
  .homescreen__links a {
    font-size: 16px; }
  .directions__text h3 {
    font-size: 20px;
    margin-bottom: 24px; }
  .directions__text p {
    font-size: 18px;
    line-height: 22px; }
  .vacancy__salary {
    font-size: 20px; }
  .vacancy__item h3 {
    font-size: 17px; }
  .vacancy__item p {
    font-size: 13px; }
  .vacancy__item ul li {
    font-size: 12px; }
  .vacancy__item {
    padding: 21px 20px 0 20px; }
  .way__slider {
    padding-left: 0; } }

@media (max-width: 424px) {
  .achievements__text h3 {
    font-size: 80px; }
  .achievements__text p {
    font-size: 18px; }
  .achievements__img {
    width: 170px;
    height: 170px; }
  .complete__item {
    margin-bottom: 10px; } }

.main-link {
  z-index: 2;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 14px; }
  .main-link:after {
    border-radius: 10px;
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: skewX(-10deg);
    background: linear-gradient(90deg, #FF8919 0%, #FF3175 100%); }
  .main-link:before {
    border-radius: 10px;
    z-index: -2;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: skewX(-10deg);
    background: linear-gradient(90deg, #FF3175 0%, #FF8919 100%);
    opacity: 0; }
  .main-link:hover:after {
    opacity: 0; }
  .main-link:hover:before {
    opacity: 1; }

.list-page {
  padding: 180px 0; }
  .list-page a {
    display: block; }

@media (max-width: 1440px) {
  html {
    zoom: 80%; }
  @-moz-document url-prefix() {
    html {
      transform: scale(0.8);
      transform-origin: left top;
      width: calc(100% / 0.80);
      height: calc(100% / 0.80); } } }
