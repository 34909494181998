
//normalize

*{position:relative;margin:0;padding:0;box-sizing:border-box}a,button,span{display:inline-block}input,textarea{display:block;width:100%}table{width:100%;border-collapse:collapse}a{background-color:transparent;text-decoration:none}img{border:none;max-width:100%;height:auto}a,button{outline:0;cursor:pointer}li{list-style:none}button{background:0 0;border:none}a,button,input,textarea{color:$black;font-weight:inherit;-webkit-appearance:none;transition:all .5s ease}:after,:before{box-sizing:border-box}textarea{resize:none;outline:0;border:none;-webkit-appearance:none;border-radius:0;background:0 0}input{outline:0;border:none;-webkit-appearance:none;border-radius:0;background:0 0}input[type=number]{-moz-appearance:textfield}input::-webkit-inner-spin-button,input::-webkit-outer-spin-button{-webkit-appearance:none}input[type=submit]{cursor:pointer;-webkit-appearance:none;display:inline-block}input[type=search]::-webkit-search-cancel-button{background:0 0;display:none}.text-align-left{text-align:left}.text-align-center{text-align:center}.text-align-right{text-align:right}.full-img img{width:100%}figure{text-align:center;margin-bottom:30px;margin-top:5px;font-size:.75em;opacity:.8}[hidden]{display:none!important}.img-full{width: 100%;}.clearfix{&:after{content: '';clear: both;display: block}}

//title

* div, a, span, input, textarea, :after, :before {
  transition: .5s;
}
h1, .h1 {
  margin: 0;
  font-weight: 900;
  font-size: 50px;
  line-height: 61px;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 1140px) {
    font-size: 40px;
    line-height: 49px;
  }
  @media (max-width: 566px) {
    font-size: 30px;
    line-height: 39px;
  }
  @media (max-width: 424px) {
font-size: 22px;
    line-height: 28px;
  }
}
h2, .h2 {
  font-weight: 900;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  max-width: 680px;
  margin: 0 auto;
  color: #37394F;
  &:after, &:before {
    content: '';
    display: block;
    position: absolute;
    top: 21px;
    width: 215px;
    height: 5px;
    background: #FF8919;
  }
  &:after {
    right: -250px;
  }
  &:before {
    left: -250px;
  }
  & span {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 1023px) {
    &:before, &:after {
      display: none;
    }
  }
  @media (max-width: 566px) {
    font-size: 30px;
    line-height: 39px;
  }
  @media (max-width: 424px) {
    font-size: 20px;
    line-height: 25px;
    & span {
      font-size: 18px;
    }
  }
}
h3, .h3 {
  margin: 0;
}
h4, .h4 {
  margin: 0;
}
h5, .h5 {
  margin: 0;
}
h6, .h6 {
  margin: 0;
}

//text

.text {

  & > * + * {

  }
}

//body

body {
  font-family: 'Montserrat', sans-serif;
  color: $black;
  font-style: normal;
  font-weight: normal;
  background: $white;
  font-size: 16px;
  @media (max-width: 735px) {
    body {
      font-size: 14px;
    }
  }
}

.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
}
main {
  flex: auto;
  display: block;
}
footer {
  margin: auto auto 0 auto;
  width: 100%;
}

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2;
}

.container {
  max-width: 1480px;
}
.container-fluid {
  max-width: 1920px;
}


//btn

.btn {

}

.btn-row {
  text-align: center;
  .btn {
    margin: 10px;
  }
}


//row

.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -20px;
  .column-2 {
    width: 50%;
    padding: 10px 20px;
  }
  .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px;
  }
}

//fancybox

.fancybox-navigation {
  position: static;
}

//swiper

.swiper-button {
  outline: none;

  &.swiper-button-prev {
    transform: scaleX(-1);
  }
  &:before, &:after {
    display: none;
  }
}

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  .swiper-pagination-bullet {
    opacity: 1;
    outline: none;
    &.swiper-pagination-bullet-active {

    }
  }
}


