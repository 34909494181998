@media (max-width: 1240px) {
  .way__item:nth-child(4) .way__line {
    transform: rotate(-35.04deg);
    left: 21px;
    top: 114px;
    width: 268px;
  }
  .way__item:nth-child(3) .way__line {
    transform: rotate(-16.58deg);
    left: 32px;
    top: 163px;
  }
  .way__item:first-child .way__line {
    transform: rotate(-23.24deg);
    left: 32px;
    top: 140px;
  }
  .homescreen__wp {
    margin-bottom: 80px;
  }
}

@media (max-width: 1160px) {
  .way__item:nth-child(4) .way__line {
    transform: rotate(-38.04deg);
  }
  .way__item:first-child .way__line {
    transform: rotate(-26.24deg);
    top: 145px;
  }
  .homescreen__text {
    width: calc(100% - 360px);
  }
  .homescreen__wrapper {
    padding: 302px 40px 147px 40px;
  }
  .homescreen__links {
    width: 340px;
    & a {
      font-size: 16px;
      padding: 18px 5px 23px 15px;
    }
  }
  .directions__text {
    padding: 30px 20px 30px 0;
  }
  .directions__block:nth-child(2n) .directions__text {
    padding-left: 35px;
  }
  .achievements__img {
   right: 0;
  }
  .achievements__item {
    margin-right: 10px;
  }
  .achievements__text h3 {
    font-size: 80px;
  }
  .achievements__text p {
    font-size: 18px;
  }
  .achievements__wp {
    flex-wrap: wrap;
    justify-content: center;
  }
  .contacts__info {
    padding-left: 0;
  }

}
@media (max-width: 1040px) {
  .way__line {
    display: none;
  }
}
@media (max-width: 900px) {
  .way__line {
    display: block;
    transform: none!important;
    top: 155px!important;
  }
  .way__item:nth-child(4) .way__line {
    width: 105%;
  }
  .contacts__form .main-link {
    width: 173px;
  }
  .homescreen__links {
    width: 440px;
  }

  .homescreen__text {
    width: calc(100% - 460px);
  }
  .homescreen__block .container {
    display: block;
  }
  .homescreen__links a {
    font-size: 20px;
  }
  .homescreen__wrapper {
    padding: 193px 0 78px 0;
  }

  .achievements__heading h2 {
    margin-bottom: 36px;
  }
  .way__item h4 {
    font-size: 36px;
    line-height: 55px;
  }
  .way__point{
    margin-bottom: 21px;
  }
  .way__item {
    margin-bottom: 0!important;
    margin-top: 0!important;
    min-width: 290px;
  }
  .achievements {
    margin-bottom: 40px;
  }
  .way__item h4 {
    margin-bottom: 63px;
  }
  .way__slider {
    margin-top: 0;
    padding-top: 52px;
    padding-bottom: 0;
    margin-bottom: 80px;
    overflow-x: scroll;
    & .swiper-slide {
      display: none;
      width: 1223px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      &:first-child {
        display: flex;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .swiper-wrapper {
    width: 100%;
  }
  .way__slider {
    padding-left: 60px;
  }
  .way__slider .swiper-scrollbar.way__scrollbar {
    display: none;
  }
  .way__bg {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
  .way__item {
    width: 290px;
  }
  .way__heading {
    margin-bottom: 50px;
  }
  .way__bg {
    top: 0;
  }
  .complete {
    margin-bottom: 58px;
  }
  .documents {
    padding: 47px 0 85px 0;
    margin-bottom: 0;
  }

}

@media (max-width: 767px) {
  .homescreen__text {
    width: 100%;
    margin-bottom: 37px;
  }
  .homescreen__links {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
  .homescreen__text h1 {
    max-width: 100%;
  }
  .contacts__form {
    max-width: 100%;
  }
  .homescreen__links a {

    padding: 31px 5px 30px 15px;
  }
  .contacts__wp {
    display: block;
  }
  .contacts__info {
    max-width: 100%;
    margin-bottom: 75px;
    &--item {
      max-width: 350px;
    }
  }
  .contacts__form .main-link {
    width: 240px;
  }
  .contacts {
    margin-bottom: 55px;
  }
  .partners .clients__slider {
    border-radius: 0;
  }
  .vacancy__heading {
    margin-bottom: 59px;
  }
  .vacancy__bg {
    top: 25px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0, #FFFFFF 30.36%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.9;
    }
  }
  .complete__slider {
    margin: 0 -20px;
  }
  .vacancy__slider {
    margin: 0 -20px;
  }
  .documents__slider {
    margin: 0 -20px;
    padding-bottom: 57px;
  }
  .clients__slider {
    border-radius: 0;
    padding: 41px 30px;
  }
  .clients {
    margin: 0 -20px;
  }
  .achievements {
    margin-bottom: 0;
  }

  .achievements__heading h2 span {
    margin-top: 0;
  }
  .achievements__wp {
    display: block;
  }
  .achievements__item {
    max-width: 100%;
    width: 450px;
  margin: 0 auto 93px auto;
    &:last-child {
      margin: 0 auto 0 auto;
    }
  }
  .achievements__text h3 {
    font-size: 100px;
  }
  .achievements__text p {
    font-size: 20px;
  }
  .achievements__text {
    max-width: 255px;
    margin-right: auto;
  }
  .achievements__img {
    opacity: 1;
    right: 30px;
  }

  .homescreen .main-link {
    width: 460px;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .homescreen__wp {
    margin-bottom: 64px;
  }
  .homescreen__wrapper {
    padding: 193px 13px 78px 13px;
  }
  .directions__img {
    position: relative;
    left: auto;
    top: auto;
    width: 100%;
  }
  .directions__block:nth-child(2n) .directions__text {
    padding-left: 13px;
  }
  .directions__text {
    width: 100%;
  }
  .directions__block {
    display: block;
  }
  .directions__text {
    padding: 26px 13px 44px 13px;
  }
  #map {
    margin-bottom: 78px;
  }

}
@media (max-width: 600px) {
  .clients__slider {
    padding: 41px 20px;
  }
}
@media (max-width: 566px) {
  .homescreen__links a {
    padding: 20px 5px 20px 15px;
  }
  .homescreen .main-link {
    padding: 17.5px;
  }
  .contacts__checkbox {
    margin-bottom: 30px;
  }
  .homescreen__links {
    max-width: 460px;
    margin: 0 auto;
  }
  .homescreen .main-link {
    font-size: 20px;
  }
  .contacts__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .contacts__form .main-link {
    width: 100%;
  }
  .homescreen__links a {
    font-size: 16px;
  }
  .directions__text h3 {
    font-size: 20px;
    margin-bottom: 24px;
  }
  .directions__text p {
    font-size: 18px;
    line-height: 22px;
  }
  .vacancy__salary {
    font-size: 20px;
  }
  .vacancy__item h3 {
    font-size: 17px;
  }
  .vacancy__item p {
    font-size: 13px;
  }
  .vacancy__item ul li {
    font-size: 12px;
  }
  .vacancy__item {
    padding: 21px 20px 0 20px;
  }
  .way__slider {
    padding-left: 0;
  }
}

@media (max-width: 424px) {
  .achievements__text h3 {
    font-size: 80px;
  }
  .achievements__text p {
    font-size: 18px;
  }
  .achievements__img {
    width: 170px;
    height: 170px;
  }
  .complete__item {
    margin-bottom: 10px;
  }
}