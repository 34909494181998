.footer {
  padding: 30px 0 43px 0;
  background: #00274C;
  & .container {
    display: flex;
  }
  &__info {
    width: 215px;
    margin-right: 153px;
  }
  &__logo {
    width: 100%;
    height: 67px;
    margin-bottom: 32px;
    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__note {
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
  }
  &__wp {
    width: calc(100% - 368px);
    display: flex;
    flex-wrap: wrap;
  }
  & .wrap-nav {
    margin-bottom: 63px;
    width: 100%;
  }
  & .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-item {
      margin-right: 7px;
      &:last-child {
        margin-right: 0;
      }
      & a {
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        &:hover {
          color: #FFA800;
        }
      }
    }
  }
  &__tel {
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    text-align: right;
    color: #FFFFFF;
    &.hidden-tel {
      display: none;
    }
    &:hover {
      color: #FFA800;
    }
    & span  {
      width: 19px;
      height: 19px;
      margin-right: 12px;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__develop {
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
    margin-left: auto;
    display: flex;
    align-items: center;
    & span {
      width: 25px;
      height: 25px;
      margin-right: 11px;
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

@media (max-width: 1340px) {

  .footer__info {
    margin-right: 30px;
  }
  .footer__wp {
    width: calc(100% - 245px);
  }
  .footer .menu-item a {
    font-size: 18px;
  }
}

@media (max-width: 1023px) {
  .footer__info {
    width: 215px;
    margin-right: 30px;
    margin-bottom: 100px;
  }
  .footer .container {
    flex-wrap: wrap;
  }
  .footer__logo {

  }
  .footer__note {
    display: block;
  }
  .footer__tel {
    display: none;
  }
  .footer__wp {
    width: 100%;
  }
  .footer__tel.hidden-tel {
    display: block;
    width: calc(100% - 245px);
  }
}

@media (max-width: 767px) {
  .footer .menu {
    display: block;
  }
  .footer .wrap-nav {
    margin-bottom: 0;
  }
  .footer .menu-item {
    margin-right: 0;
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0;
    }
    & a {
      font-size: 20px;
    }
  }
  .footer__develop {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 566px) {
  .footer__tel.hidden-tel {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
    text-align: left;
  }
  .footer__info {
    margin: 0 auto 30px auto;
    text-align: center;
  }
  .footer__develop {
    position: relative;
    right: auto;
    top: 0;
    margin: 0 auto;
  }
  .footer .wrap-nav {
    margin-bottom: 30px;
  }
  .footer .menu-item {
    text-align: center;
  }
  .footer__tel.hidden-tel {
    text-align: center;
  }
}