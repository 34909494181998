.homescreen {

  &__slider {

  }
  &__wrapper {
    padding: 302px 0 147px 0;
  }
    &__wp {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 123px;
    }
  &__text {
    width: calc(100% - 560px);

    & h1 {
      max-width: 580px;
    }
  }
  &__links {
    width: 540px;
    z-index: 5;
    margin-left: 20px;
    margin-top: 10px;
    & a {
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: #FFFFFF;
       width: 100%;
      margin-bottom: 30px;
      padding: 28px 10px 33px 35px;
      &:hover {
        &:after {
          transform: skewX(10deg);
        }
      }
      &:after {
        z-index: -1;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #73C167;
        transform: skewX(-10deg);
      }
      &:last-child {
        margin-bottom: 0;
        &:after {
          background: #EE3524;
        }
      }
      &:nth-child(2) {
        &:after {
          background: #FF8919;
        }
      }
    }
  }
  &__bg {
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(17, 88, 156, 0.8) 0%, rgba(46, 141, 231, 0.2) 100%);
    }
  }
  & .main-link {
    width: 460px;
    padding: 36px;
    font-size: 24px;
    line-height: 29px;
    padding: 31.5px;
  }
}


.directions {
  background: #EEEEEE;
  &__text {
    width: 50%;
    padding: 50px 40px 60px 0;
    & h3 {
      font-weight: 900;
      font-size: 25px;
      line-height: 30px;
      text-transform: uppercase;
      color: #37394F;
      margin-bottom: 44px;
    }
    & p {
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
      margin-bottom: 18px;
    }
    & .main-link {
      margin-top: 22px;
      width: 245px;
    }
  }
  &__block {
    display: flex;
    align-items: stretch;
    &:nth-child(2n) {
      .directions__text {
        padding-left: 75px;
        margin-left: auto;
      padding-right: 0;
      }
      .directions__img {
        right: auto;
        left: 0;
      }
    }
  }
  &__img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.achievements {
  margin-bottom: 90px;
  padding: 55px 0 90px 0;
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(17, 88, 156, 0.5), rgba(17, 88, 156, 0.5)), linear-gradient(180deg, rgba(17, 88, 156, 0.8) 0%, rgba(46, 141, 231, 0.2) 100%);
    }
  }
  &__heading {
    max-width: 705px;
    margin: 0 auto 64px auto;
    & h2 {
      max-width: 480px;
      color: #FFFFFF;
      margin-bottom: 40px;
      & span {
        margin-top: -15px;
      }
    }
    & p {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
    }
  }
  &__wp {
    display: flex;
    max-width: 1330px;
    justify-content: space-between;
  }
  &__item {
    width: 30%;
  }
  &__text {
     & span {
       font-weight: 900;
       font-size: 20px;
       line-height: 24px;
       text-align: right;
       text-transform: uppercase;
       color: #FFFFFF;
       width: 100%;
     }
    & h3 {
      font-weight: 900;
      font-size: 100px;
      line-height: 122px;
      text-align: right;
      text-transform: uppercase;
      color: #FFFFFF;
    }
    & p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: right;
      text-transform: uppercase;
      color: #FFFFFF;
      max-width: 290px;
      margin-left: auto;
    }
  }
  &__img {
    z-index: -1;
    position: absolute;
    top: -20px;
    right: -120px;
    width: 200px;
    height: 200px;
    opacity: .5;
    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.complete {
  margin-bottom: 115px;
  &__slider {
    padding-bottom: 60px;
  }
  & .swiper-scrollbar.complete__scrollbar {
    width: 100%;
    height: 1px;
    background: #686868;
    border-radius: 0;
    & .swiper-scrollbar-drag {
      background: #73C167;
      height: 5px;
      top: -2px;
    }
  }
  &__heading {
    margin-bottom: 54px;
    & h2 {
      margin-bottom: 17px;
      &:after, &:before {
        width: 93px;
      }
      &:after {
        right: -150px;
      }
      &:before {
        left: -150px;
      }
    }
    & p {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
    }
  }
  &__bg {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .5s;
    }
  }
  &__item {
    margin-bottom: 30px;
    min-height: 253px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      & .complete__bg {
        & img {
          transition: .5s;
          transform: scale(1.1);
        }
      }
    }
  }
  &__text {
    background: rgba(255, 255, 255, 0.8);
    padding: 12px 10px 12px 14px;
    & p {
      font-size: 18px;
      line-height: 21px;
      color: #37394F;

      @media (max-width: 900px) {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}

.documents {
  padding: 73px 90px 83px 90px;
  margin-bottom: 90px;
  & h2 {
    color: #FFFFFF;
    max-width: 710px;
    margin-bottom: 60px;
    &:after, &:before {
      width: 93px;
    }
    &:after {
      right: -150px;
    }
    &:before {
      left: -150px;
    }
  }
  &__slider {
    padding: 0 0 60px 0;
    max-width: 1255px;
    margin: 0 auto;
  }
  &__item {
    overflow: hidden;
    & img {
      transition: .5s;
    }
    &:hover {
      opacity: .7;
      & img {
        transition: .5s;
      }
    }
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
  }
  & .swiper-scrollbar.documents__scrollbar {
    background: #FFFFFF;
    height: 1px;
    & .swiper-scrollbar-drag {
      background: #73C167;
      height: 5px;
    }
  }
}

.way {
  margin-bottom: 60px;
  &__bg {
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    opacity: 0.6;
    z-index: -1;
    bottom: 0;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__slider {
    margin-bottom: 90px;
    padding-bottom: 80px;
    margin-top: -60px;
    padding-top: 60px;
    & .swiper-scrollbar.way__scrollbar {
      background: #686868;
      height: 1px;
      & .swiper-scrollbar-drag {
        background: #73C167;
        height: 5px;
        top: -2.5px;
      }
    }
  }
  &__heading {
    margin-bottom: 60px;
    & h2 {
      margin-bottom: 12px;
      &:after {
        right: -130px;
      }
      &:before {
        left: -130px;
      }
    }
    & p {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
    }
  }
  &__wp {
    display: flex;
    align-items: flex-end;
  }
  &__point {
    transition: .5s;
    background: #EE3524;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    margin-bottom: 52px;
    background: url("../img/icons/circle.svg") no-repeat center;
    background-size: contain;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 99%;
      height: 114px;
      width: 1px;
      background: #C4C4C4;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 99%;
      height: 70px;
      width: 1px;
      background: #C4C4C4;
    }
  }
  &__line {
    width: 100%;
    height: 9px;
    left: 0;
    top: 10px;
    position: absolute;
    background: #FFA71E;
    height: 1px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -5px;
      right: 0;
      bottom: -5px;
      background: rgba(255, 167, 30, 0.2);
    }
  }
  &__item {
    width: 20%;
    &:hover {
      & .way__point {
        transform: scale(1.1);
        transition: .5s;
      }
    }
    &:first-child {
      & .way__line {
        transform: rotate(-21.24deg);
        left: 32px;
        top: 138px;
      }
    }
    &:nth-child(2) {
      & .way__line {
        transform: rotate(5.34deg);
        left: 32px;
        top: 205px;
      }
    }
    &:nth-child(3) {
      & .way__line {
        transform: rotate(-13.58deg);
        left: 32px;
        top: 155px;
      }
    }
    &:nth-child(4) {
      & .way__line {
        transform: rotate(-31.04deg);
        left: 0px;
        top: 116px;
        width: 330px;
      }
    }
    &:last-child {
      & .way__line {
        display: none;
      }
    }

    & h4 {
      font-weight: 900;
      font-size: 50px;
      line-height: 61px;
      color: #73C167;
      margin-bottom: 94px;
      padding-left: 52px;
    }

    & p {
      padding-left: 60px;
      font-size: 18px;
      line-height: 21px;
      color: #37394F;
      max-width: 280px;
      @media (max-width: 900px) {
        font-size: 15px;
        line-height: 18px;
      }
    }
    &:nth-child(2) {
      margin-bottom: 110px;
    }
    &:nth-child(3) {
      margin-bottom: 87px;
    }
    &:nth-child(4) {
      margin-bottom: 160px;
    }
    &:last-child {
      margin-bottom: 330px;
      margin-top: -60px;
    }
  }
}

.clients {

  &__slider {
    max-width: 1255px;
    margin: 0 auto;
    padding: 29px 70px;
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__next, &__prev {
    border: 4px solid #C4C4C4;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    &:after {
      color: #C4C4C4;
      font-size: 25px;
    }
  }
}

.partners {
  &__heading {
    margin-bottom: 60px;
    & h2 {
      margin-bottom: 12px;
      &:after {
        right: -150px;
      }
      &:before {
        left: -150px;
      }
    }
    & p {
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #37394F;
    }
  }
  & .clients {
    &__slider {
      border-radius: 0 0 20px 20px;
    }
  }
}

.vacancy {
  padding: 243px 0 0 0;
  margin-bottom: 100px;
  margin-top: -158px;
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 100px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    }
  &__heading {
    margin-bottom: 63px;
    & h2 {
      margin-bottom: 12px;

      &:after {
        right: -150px;
      }
      &:before {
        left: -150px;
      }
    }
    & p {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #37394F;
    }
  }
  &__slider {
    padding-bottom: 73px;
    & .swiper-scrollbar.vacancy__scrollbar {
      background: #686868;
      height: 1px;
      & .swiper-scrollbar-drag {
        background: #73C167;
        height: 5px;
        top: -2.5px;
      }
    }
  }
  &__item {
    padding: 31px 30px 0 30px;
    background: #FFFFFF;
    border: 1px solid transparent;
    transition: .5s;
    &:hover {
      border: 1px solid #B8B8B8;
      transition: .5s;
    }
    & h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 100%;
      color: #37394F;
      padding-bottom: 23px;
      margin-bottom: 23px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 33px;
        height: 2px;
        background: #FFA800;
      }
    }
    & p {
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      color: #686868;
      margin-bottom: 23px;
    }
    & ul {
      margin-bottom: 32px;
      & li {
        font-size: 16px;
        line-height: 100%;
        color: #686868;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    & .main-link {
      max-width: 245px;
      width: 100%;
      margin: 0 auto -24px auto;
      top: 7px;
    }
  }
  &__salary {
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    color: #00274C;
    margin-bottom: 47px;
  }
}

#map {
  height: 472px;
  width: 100%;
  margin-bottom: 65px;
}

.contacts {
  margin-bottom: 85px;
  &__wp {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    max-width: 520px;
    width: 100%;
    padding-left: 120px;
    margin-right: 20px;
    & h2 {
      margin: 0 0 30px 0;
      text-align: left;
      &:after, &:before {
        display: none;
      }
    }
    &--item {
      display: flex;
      display: flex;
      align-items: flex-start;
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
      margin-bottom: 20px;
      &:first-child {
        pointer-events: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      & span {
        width: 20px;
        height: 20px;
        margin-right: 19px;
        & img {
          width: 100%;
          height: 100%;
        }
      }
      &:hover {
        color: #FFA71E;
      }
    }
  }
  &__form {
    max-width: 540px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    & h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
      color: #37394F;
    }
    & .main-link {
      width: 245px;
      margin-left: auto;
      padding: 0;
      height: 49px;
    }
  }
  &__item {
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 35px;
    & input {
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
      padding: 0 10px 12px 14px;
      border-bottom: 1px solid #686868;
      &::placeholder {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        color: #37394F;
      }
    }
  }
  &__checkbox {
    cursor: pointer;
    display: block;
    width: 220px;
    padding-left: 38px;
    font-size: 17px;
    line-height: 20px;
    color: #37394F;
    & input {
      background: #37394F;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      width: 21px;
      height: 21px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
  &__check {
    background: #37394F;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 21px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background: url("../img/icons/ok.svg") no-repeat center;
      opacity: 0;
    }
  }
}

.contacts__checkbox input:checked ~ .contacts__check:before {
  opacity: 1;
}
