.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  padding: 37px 0 0 0;
  transition: .5s;
  &.out {
    transform: translateY(-100%);
    transition: .5s;
  }
  &.sticky {
    padding: 0;
    .header__info {
      display: none;
    }
    & .header__menu {
      &:after {
        opacity: 1;
      }
    }
    & .menu-item a {
      padding: 30px 0 18px 0;
    }
    & .header__logo {
      margin-right: 55px;
      margin-left: 0;
      height: 42px;
      & span {
        display: none;
      }
      & img {
        display: none;
        &.fixed-logo {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__info {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__item {
    margin-right: 30px;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    & span {
      width: 25px;
      height: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &:first-child {
      pointer-events: none;
    }
    &:hover {
      color: #FFA71E;
    }
    &:first-child {
      max-width: 270px;
    }
    &:nth-child(2) {
      margin-right: 74px;
      font-size: 16px;
    }
    &:last-child {
      margin-right: 0;
      font-weight: 500;
      font-size: 30px;
      line-height: 37px;

    }
  }
  &__menu {
    display: flex;
    background: #FFFFFF;
    border-radius: 3px;
    align-items: center;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -2000px;
      right: -2000px;
      top: 0;
      bottom: 0;
      background: #FFFFFF;
      z-index: -1;
      opacity: 0;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }
  }
  &__logo {
    width: 245px;
    height: 19px;
    margin-left: 30px;
    margin-right: 65px;
    &--icon {
      position: absolute;
      width: 110px;
      height: 81px;
      bottom: 170%;
      left: 65px;
    }
    & img {
      max-width: 100%;
      max-height: 100%;
      &.fixed-logo {
        display: none;
      }
    }
  }
  .wrap-nav {
    flex: 1;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-item {
      margin-right: 10px;
      & a {
        padding: 15px 0 15px 0;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #686868;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          background: #73C167;
          width: 0;
          opacity: 0;
        }
        &:hover {
          color: #000000;
          &:after {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
  &.header-page {
    background: #00274C;
  }
  & .header__menu:after {

  }
  & .header__menu {
    border-radius: 3px 3px 0 0;
  }

}
.open-nav {
  display: none;
  width: 16px;
  height: 18px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  & .line {
    height: 4px;
    background: #C4C4C4;
    width: 100%;
  }
}
.close-nav {
  display: none;
  width: 16px;
  height: 18px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  & .line {
    height: 4px;
    background: #C4C4C4;
    width: 100%;
  }
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: none;
  &.open {
    display: block;
  }
}
@media (max-width: 1340px) {
  .header {
    padding: 15px 0 0 0;
  }
  .header__item:last-child {
    font-size: 25px;
  }
  .header__item:nth-child(2) {
    margin-right: 30px;
  }
  .header__item span {
    margin-right: 15px;
  }
  .header__logo {
    width: 200px;
    margin-left: 10px;
    margin-right: 25px;
  }
  .header .menu-item a {
    font-size: 17px;
    padding: 10px 0 10px 0
  }
  .header__logo--icon {
    position: absolute;
    width: 95px;
    height: 65px;
    bottom: 150%;
    left: 50px;
  }
}

@media (max-width: 1023px) {
  .header.sticky .header__menu:after {
    display: none;
  }
  .header.sticky .header__menu {
    padding: 6px 19px 6px 29px;
    margin: 0 -8px;
  }
  .header.sticky {
    padding-top: 7px;
  }
  .header__item:first-child {
    display: none;
  }
  .header__item:nth-child(2) {
    display: none;
  }
  .header__item:last-child {
    font-size: 30px;
    font-weight: normal;
  }
  .header .wrap-nav {
    transform: translateX(1000%);
    z-index: 99999;
    flex: auto;
    position: fixed;
    right: 5px;
    top: 7px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    transition: .5s;
    padding: 66px 13px 30px 30px;
    &.open {
      transform: translateX(0);
      transition: .5s;
    }

  }
  .header {
    padding: 36px 0 0 0;
  }
  .header__logo--icon {
    bottom: 188%;
  }
  .header__logo {
    width: 245px;
  }
  .header__logo {
    margin-left: 0;
  }
  .header__menu {
    padding: 20px 19px 15px 29px;
  }
  .close-nav {
    display: flex;
    position: absolute;
    right: 19px;
    top: 18px;
  }
  .header .menu-item a {
    padding: 0 0 6px 0;
  }
  .header.sticky .menu-item a {
    padding: 0 0 6px 0;
  }
  .header .menu-item  {
    margin-right: 0;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .header .menu {
    display: block;
  }
  .open-nav {
    display: flex;
  }
}
@media (max-width: 474px) {
  .header__logo--icon {
    left: -20px;
  }
  .header__item:last-child {
    font-size: 20px;
  }
}
@media (max-width: 424px) {
  .header__logo {
    width: 180px;
  }

}