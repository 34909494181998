.main-link {
  z-index: 2;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 14px;
  &:after {
    border-radius: 10px;
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: skewX(-10deg);
    background: linear-gradient(90deg, #FF8919 0%, #FF3175 100%);
  }
  &:before {
    border-radius: 10px;
    z-index: -2;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: skewX(-10deg);
    background: linear-gradient(90deg, #FF3175 0%, #FF8919 100%);
    opacity: 0;
  }
  &:hover {
    &:after {
  opacity: 0;
    }
    &:before {
      opacity: 1;
    }
  }
}

.list-page {
  padding: 180px 0 ;
  & a {
    display: block;
  }
}


@media  (max-width: 1440px) {
  html {
    zoom: 80%;
  }
  @-moz-document url-prefix() {
    html {
      transform: scale(0.80);
      transform-origin: left top;
      width: calc(100% / 0.80);
      height: calc(100% / 0.80);
    }
  }
}