/*modal*/

.modal {
  display: none;
  max-width: 100%;
  overflow: visible;
  padding: 87px 33px 39px 34px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: 510px;
  @media (max-width: 424px) {
    padding: 67px 13px 29px 13px;
  }
  .main-link {
    width: 240px;
    margin: 0 auto;
    display: block;
  }
  & h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #37394F;
    margin-bottom: 30px;
  }
  .fancybox-close-small {
    width: 52px;
    height: 52px;
    & svg {
      color: #C4C4C4;
    }
  }
}

.form-item {
  width: 100%;
  max-width: 390px;
  margin: 0 auto 45px auto;
  &:last-child {
    margin: 0 auto 33px auto;
  }
  & input {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #37394F;
    padding: 0 12px 12px 20px;
    border-bottom: 1px solid #686868;
    &::placeholder {
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #37394F;
    }
  }
}
.form-checkbox {
  font-size: 12px;
  line-height: 15px;
  color: #37394F;
  margin-bottom: 50px;
  padding-left: 38px;
  display: block;
  max-width: 175px;
  & input {
    position: absolute;
    left: 0;
    top: 0;
    width: 21px;
    height: 21px;
    background: #37394F;
    border-radius: 3px;
    z-index: -1;
  }
}
.form-check {
  position: absolute;
  left: 0;
  top: 0;
  width: 21px;
  height: 21px;
  background: #37394F;
  border-radius: 3px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 11px;
    height: 11px;
    transform: translate(-50%, -50%);
    background: url("../img/icons/ok.svg") no-repeat center;
    background-size: contain;
    opacity: 0;
  }
}
.form-checkbox input:checked ~ .form-check:before {
  opacity: 1;
}

.modal, .modal > * {
  -webkit-transform: translateY(150px);
  -moz-transform: translateY(150px);
  -ms-transform: translateY(150px);
  -o-transform: translateY(150px);
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s;
}

.fancybox-slide--current .modal, .fancybox-slide--current .modal > * {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

